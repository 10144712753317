<template>
  <div>
    <b-modal
      id="createQuesitonModal"
      title="Add Question"
      size="lg"
      hide-footer
    >
      <validation-observer
        ref="createQuesitonModal"
      >
        <b-form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit.prevent="createQuesiton"
        >
          <!-- Templates -->
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Select Template"
                label-for="vi-select-template"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select Template"
                  vid="vi-select-template"
                  rules="required"
                >
                  <v-select
                    v-model="form.template_id"
                    class="p-0 border-0"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="template_name"
                    :reduce="temp => temp.id"
                    placeholder="Select Template"
                    :options="templateList"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Question -->
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="t('Question')"
                label-for="vi-question"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Question"
                  vid="vi-question"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      id="vi-question"
                      v-model="form.question"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Question')"
                      rows="3"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Type -->
          <b-row>
            <b-col :cols="form.group_id == constants.TEMPLATE_GROUP_SELECT ? '4' : '6'">
              <b-form-group
                label="Select Group"
                label-for="vi-select-group_id"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Group"
                  vid="vi-select-group_id"
                  rules="required"
                >
                  <v-select
                    v-model="form.group_id"
                    class="p-0 border-0"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="para_desc"
                    :reduce="grp => grp.para_id"
                    placeholder="Select Group"
                    :options="groupList"
                    @input="getSelectedGroupJson"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              :cols="form.group_id == constants.TEMPLATE_GROUP_SELECT ? '4' : '6'"
              class="d-flex align-items-center"
            >
              <validation-provider
                vid="vi-required"
                rules=""
              >
                <b-form-checkbox
                  v-model="form.required"
                  class="col-md-6 my-1"
                >
                  {{ t('Required') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.group_id == constants.TEMPLATE_GROUP_SELECT"
              cols="4"
              class="d-flex align-items-center"
            >
              <validation-provider
                vid="vi-multiple"
                rules=""
              >
                <b-form-checkbox
                  v-model="form.multiple"
                  class="col-md-6 my-1"
                >
                  {{ t('Multiple') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-if="form.group_id !== constants.TEMPLATE_GROUP_TEXT && form.group_id !== constants.TEMPLATE_GROUP_FILE && form.group_id !== constants.TEMPLATE_GROUP_REPETER">
            <b-col cols="6">
              <validation-provider
                vid="vi-from-master"
                rules=""
              >
                <b-form-checkbox
                  v-model="form.from_master"
                  class="col-md-6 my-1"
                  value="1"
                >
                  {{ t('Master') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>

            <b-col
              v-if="form.from_master"
              cols="6"
            >
              <b-form-group
                label="Select From Master"
                label-for="vi-select-type"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select From Master"
                  vid="vi-select-type"
                  rules="required"
                >
                  <v-select
                    v-model="form.master_table"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="form-control p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="typ => typ.id"
                    placeholder="Select From Master"
                    :options="selectFromMasterList"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col
              v-if="form.is_master !== '' && !form.is_master"
              cols="6"
            >
              <b-form-group
                :label="t('Text')"
                label-for="vi-Options"
                class=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Text"
                  vid="vi-Text"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Text"
                      v-model="form.free_text"
                      type="text"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Text')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

          </b-row>

          <!-- <b-row>
        <b-col cols="6">
          <b-form-group
            label="Select Services"
            label-for="vi-select-service"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Select Services"
              vid="vi-select-service"
              rules="required"
            >
              <v-select
                v-model="form.service_id"
                class="form-control p-0 border-0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="service_name"
                :reduce="srv => srv.id"
                placeholder="Select Services"
                :options="servicesList"
              />
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row> -->

          <b-row
            v-if="form.group_id !== constants.TEMPLATE_GROUP_TEXT && form.group_id !== constants.TEMPLATE_GROUP_FILE  && form.group_id !== constants.TEMPLATE_GROUP_REPETER && form.from_master == false"
          >
            <b-col cols="6">
              <b-form-group
                :label="t('Type No. Options')"
                label-for="vi-Options"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Options"
                  vid="vi-Options"
                  :rules="`required|between:1,${optionLimit}`"
                >
                  <!-- rules="integer|min:10" -->
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Options"
                      v-model="form.num_of_options"
                      type="number"
                      maxlength="15"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Type Options')"
                      @input="addNoOfOptions"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-for="(op, i) in options"
            :key="i"
          >
            <b-col
              v-if="form.group_id !== constants.TEMPLATE_GROUP_TEXT && form.group_id !== constants.TEMPLATE_GROUP_FILE  && form.group_id !== constants.TEMPLATE_GROUP_REPETER"
              :class="selectedGroupJson && selectedGroupJson.is_other_text ? 'col-8' : 'col-12'"
            >
              <b-form-group
                :label="t('Option')+(i+1)"
                label-for="vi-options"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="`Option${(i+1)}`"
                  :vid="`vi-options${(i+1)}`"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      :id="`vi-options${(i+1)}`"
                      v-model="form.options[i].option"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Option')+(i+1)"
                      rows="1"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="selectedGroupJson && selectedGroupJson.is_other_text"
              class="col-4 d-flex justify-content-center align-self-center"
            >
              <validation-provider
                #default="{ errors }"
                :name="`is_other_text${(i+1)}`"
                :vid="`vi-is_other_text${(i+1)}`"
                rules=""
              >
                <b-form-checkbox
                  v-model="form.options[i].is_other_text"
                  value="1"
                  class="my-1"
                >
                  {{ t('Other Text') }}
                </b-form-checkbox>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- repeter -->
            <div class="add-more" v-for="(item, index) in form.repeter"
            :key="item.id">
            <b-row>
            <b-col cols="12">
              <b-form-group
                :label="t('Question')"
                label-for="vi-question"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Question"
                  vid="vi-question"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      id="vi-question"
                      v-model="item.question"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Question')"
                      rows="3"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Type -->
          <b-row>
            <b-col :cols="item.group_id == constants.TEMPLATE_GROUP_SELECT ? '4' : '6'">
              <b-form-group
                label="Select Group"
                label-for="vi-select-group_id"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Group"
                  vid="vi-select-group_id"
                  rules="required"
                >
                  <v-select
                    v-model="item.group_id"
                    class="p-0 border-0"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="para_desc"
                    :reduce="grp => grp.para_id"
                    placeholder="Select Group"
                    :options="groupList"
                    @input="getSelectedGroupJson()"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              :cols="item.group_id == constants.TEMPLATE_GROUP_SELECT ? '4' : '6'"
              class="d-flex align-items-center"
            >
              <validation-provider
                vid="vi-required"
                rules=""
              >
                <b-form-checkbox
                  v-model="item.required"
                  class="col-md-6 my-1"
                >
                  {{ t('Required') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>

            <b-col
              v-if="item.group_id == constants.TEMPLATE_GROUP_SELECT"
              cols="4"
              class="d-flex align-items-center"
            >
              <validation-provider
                vid="vi-multiple"
                rules=""
              >
                <b-form-checkbox
                  v-model="item.multiple"
                  class="col-md-6 my-1"
                >
                  {{ t('Multiple') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row v-if="item.group_id !== constants.TEMPLATE_GROUP_TEXT && item.group_id !== constants.TEMPLATE_GROUP_FILE">
            <b-col cols="6">
              <validation-provider
                vid="vi-from-master"
                rules=""
              >
                <b-form-checkbox
                  v-model="item.from_master"
                  class="col-md-6 my-1"
                  value="1"
                >
                  {{ t('Master') }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>

            <b-col
              v-if="item.from_master"
              cols="6"
            >
              <b-form-group
                label="Select From Master"
                label-for="vi-select-type"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select From Master"
                  vid="vi-select-type"
                  rules="required"
                >
                  <v-select
                    v-model="item.master_table"
                    :class="errors[0] ? 'border-red-vselect' : ''"
                    class="form-control p-0 border-0"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :reduce="typ => typ.id"
                    placeholder="Select From Master"
                    :options="selectFromMasterList"
                  />
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="item.group_id !== constants.TEMPLATE_GROUP_TEXT && item.group_id !== constants.TEMPLATE_GROUP_FILE && item.from_master == false"
          >
            <b-col cols="6">
              <b-form-group
                :label="t('Type No. Options')"
                label-for="vi-Options"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Options"
                  vid="vi-Options"
                  :rules="`required|between:1,${optionLimit}`"
                >
                  <!-- rules="integer|min:10" -->
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="vi-Options"
                      v-model="item.num_of_options"
                      type="number"
                      maxlength="15"
                      autocomplete="off"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Type Options')"
                      @input="addNoOfOption(index)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-for="(op, i) in item.options"
            :key="i"
          >
            <b-col
              v-if="item.group_id !== constants.TEMPLATE_GROUP_TEXT && item.group_id !== constants.TEMPLATE_GROUP_FILE"
              :class="selectedGroupJson && selectedGroupJson.is_other_text ? 'col-8' : 'col-12'"
            >
              <b-form-group
                :label="t('Option')+(i+1)"
                label-for="vi-options"
                class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="`Option${(i+1)}`"
                  :vid="`vi-options${(i+1)}`"
                  rules="required"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-textarea
                      :id="`vi-options${(i+1)}`"
                      v-model="item.options[i].option"
                      :class="errors[0] ? 'border-red' : ''"
                      :placeholder="t('Option')+(i+1)"
                      rows="1"
                      autocomplete="off"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="selectedGroupJson && selectedGroupJson.is_other_text"
              class="col-4 d-flex justify-content-center align-self-center"
            >
              <validation-provider
                #default="{ errors }"
                :name="`is_other_text${(i+1)}`"
                :vid="`vi-is_other_text${(i+1)}`"
                rules=""
              >
                <b-form-checkbox
                  v-model="item.options[i].is_other_text"
                  value="1"
                  class="my-1"
                >
                  {{ t('Other Text') }}
                </b-form-checkbox>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="m-1"
                @click="removeItem(index)"
              >
              {{ t('Remove') }}
          </b-button>
            </b-col>
          </b-row>
          </div>
          <b-button v-show="form.group_id == constants.TEMPLATE_GROUP_REPETER"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="primary"
                class="m-1"
                @click="addItem"
              >
              {{ t('Add') }}
          </b-button>
          <b-col
            cols="12"
            class="text-center mt-8"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
            >
              {{ t('Submit') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetQuestionForm"
            >
              {{ t('Cancel') }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import constants from '@/constants'
// eslint-disable-next-line object-curly-newline
import { /* failedToast, */ failedToast, hideLoader, showLoader, successToast,
} from '@/utils/common'

export default {
  name: 'AddQuestion',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    addtempques: {
      type: Object,
      required: false,
      default: () => {},
    },
    editques: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup() {
    const { t } = useUtils()
    return {
      t,
    }
  },
  data() {
    return {
      constants,
      required,
      groupList: [],
      editQuestion: false,
      templateList: [],
      options: [],
      selectedGroupJson: {},
      selectFromMasterList: [],
      optionLimit: 0,
      form: {
        question_id: '',
        template_id: '',
        required: false,
        multiple: false,
        num_of_options: '',
        from_master: 0,
        master_table: '',
        options: [],
        question: '',
        group_id: '',
        repeter: [],
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.from_master': function (val) {
      if (val) {
        this.getMasterTable()
      }
    },
    addtempques: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.form.template_id = val.id
        }
      },
    },
    editques: {
      deep: true,
      handler(val) {
        if (Object.entries(val).length) {
          this.editQuestion = true
        }
        this.form.question_id = val.question_master_id
      },
    },
  },
  created() {
    this.getTemplateList()
    this.getOptionLimit()
    this.getGroupList()
  },
  mounted() {
    this.$root.$on('refresTemplateList', () => {
      this.getTemplateList()
    })
    this.$root.$on('bv::modal::shown', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'createQuesitonModal') {
        // eslint-disable-next-line eqeqeq
        if (this.editQuestion == true) {
          this.getQuesitonById()
        }
      }
    })
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'createQuesitonModal') {
        this.editQuestion = false
        this.options = []
        this.form = {
          template_id: this.addtempques ? this.addtempques.id : '',
          num_of_options: '',
          from_master: 0,
          required: false,
          options: [],
          question: '',
          group_id: '',
          repeter: [],
        }
      }
    })
  },
  methods: {
    addItem() {
      this.form.repeter.push(
        {
          id: this.form.repeter.length + 1,
          question_id: '',
          required: false,
          multiple: false,
          num_of_options: '',
          from_master: 0,
          master_table: '',
          options: [],
          question: '',
          group_id: '',
        },
      )
    },
    removeItem(index) {
      this.form.repeter.splice(index, 1)
    },
    getSelectedGroupJson() {
      // eslint-disable-next-line eqeqeq
      this.selectedGroupJson = this.groupList.filter(grp => grp.para_id == this.form.group_id)['0']
      if (this.selectedGroupJson.para_id === constants.TEMPLATE_GROUP_TEXT || this.selectedGroupJson.para_id === constants.TEMPLATE_GROUP_FILE) {
        this.form.num_of_options = '1'
        this.addNoOfOptions()
      }
      if (this.form.repeter.length === 0 && this.selectedGroupJson.para_id === constants.TEMPLATE_GROUP_REPETER) {
        this.addItem()
      }
    },
    getOptionLimit() {
      store.dispatch('questionsStore/getTemplateDD').then(response => {
      // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) { this.optionLimit = response.data.data.dynamic_form_option }
      })
    },
    getTemplateList() {
      store.dispatch('questionsStore/getTemplateList', { status: constants.USER_STATUS_ACTIVE }).then(response => {
      // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) { this.templateList = response.data.data.data }
      })
    },
    getGroupList() {
      store.dispatch('questionsStore/getGroupList').then(response => {
      // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) { this.groupList = response.data.data }
      })
    },

    async getQuesitonById() {
      showLoader()
      await store.dispatch('questionsStore/getQuestionById', { question_id: this.form.question_id }).then(response => {
      // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.form.question_id = response.data.data.question_id
          this.form.template_id = response.data.data.template_id
          this.form.num_of_options = response.data.data.num_of_options
          this.form.from_master = response.data.data.from_master
          this.form.status = response.data.data.status
          this.form.required = !!response.data.data.is_required
          this.form.master_table = response.data.data.master_table
          this.options = response.data.data.options.length
          this.form.options = response.data.data.options
          this.form.question = response.data.data.question
          this.form.group_id = response.data.data.group_id
          // eslint-disable-next-line eqeqeq
          this.selectedGroupJson = this.groupList.filter(grp => grp.para_id == this.form.group_id)['0']
          hideLoader()
        }
      })
      hideLoader()
    },

    addNoOfOptions() {
      if (this.form.num_of_options <= this.optionLimit) {
        this.form.options = []
        this.options = []
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= this.form.num_of_options; i++) {
          this.options.push({ i: '' })
          this.form.options.push({ option: '', is_other_text: '' })
          // this.form.options.push({ name: '', is_other_text: false })
        }
      } else {
        this.options = []
      }
    },
    addNoOfOption(index) {
      if (this.form.repeter[index].num_of_options <= this.optionLimit) {
        this.form.repeter[index].options = []
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= this.form.repeter[index].num_of_options; i++) {
          this.form.repeter[index].options.push({ option: '', is_other_text: '' })
        }
      }
    },
    async getMasterTable() {
      showLoader()
      await store.dispatch('questionsStore/getMasterTable').then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.selectFromMasterList = response.data.data
          hideLoader()
        }
      })
      hideLoader()
    },
    resetQuestionForm() {
      this.$bvModal.hide('createQuesitonModal')
    },
    createQuesiton() {
      this.$refs.createQuesitonModal.validate().then(success => {
        if (success) {
          if (this.editQuestion) {
            this.updateQuestion()
          } else {
            this.saveQuestion()
          }
        }
      })
    },
    async saveQuestion() {
      showLoader()
      await store.dispatch('questionsStore/saveQuestion', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          hideLoader()
          this.$bvModal.hide('createQuesitonModal')
          this.$root.$emit('refreshQuestionList')
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
      hideLoader()
    },
    async updateQuestion() {
      showLoader()
      await store.dispatch('questionsStore/updateQuestion', this.form).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          hideLoader()
          this.$bvModal.hide('createQuesitonModal')
          this.$root.$emit('refreshQuestionList')
        } else {
          failedToast(response.data.msg)
        }
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
      hideLoader()
    },
  },
}
</script>

<style>
.mt-8 {
  margin-top: 8rem !important;
}
.add-more {
  border: 1px solid #8b8888;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>
